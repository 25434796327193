import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folder: [],
  seniortyfolder: [],
  circularpdf: [],
  imagedata: [],
  aaykar: [],
  incometaxslab: [],
  constitution: [],
  delhicircle: [],
  itefchq: [],
  departmental: [],
  recruitment: [],
  organistaion: [],
  centralheadquarters: [],
  ccitdelhicircularpromotionorder: [],
  ccitdelhicircularseniortylist: [],
  ccitdelhicirculartandporder: [],
  ccitdelhicircularother: [],
  
  
};

export const pdfSlice = createSlice({
  name: "pdf",
  initialState,
  reducers: {
    addfolder: (state, action) => {
      state.folder = action.payload;
    },
    addseniortyfolder: (state, action) => {
      state.seniortyfolder = action.payload;
    },

    addcircularpdf: (state, action) => {
      state.circularpdf = action.payload;
    },
    addimagedata: (state, action) => {
      state.imagedata = action.payload;
    },
    addaaykardata: (state, action) => {
      state.aaykar = action.payload;
    },
    addincometaxslabdata: (state, action) => {
      state.incometaxslab = action.payload;
    },
    addconstitutiondata: (state, action) => {
      state.constitution = action.payload;
    },
    adddehicircledata: (state, action) => {
      state.delhicircle = action.payload;
    },
    additefchqdata: (state, action) => {
      state.itefchq = action.payload;
    },
    adddepartmentaldata: (state, action) => {
      state.departmental = action.payload;
    },
    addrecruitmentdata: (state, action) => {
      state.recruitment = action.payload;
    },
    addorganistaiondata: (state, action) => {
      state.organistaion = action.payload;
    },
    addcentralheadquartersdata: (state, action) => {
      state.centralheadquarters = action.payload;
    },
    addccitdelhicirclepromotionorder: (state, action) => {
      state.ccitdelhicircularpromotionorder = action.payload;
    },
    addccitdelhicircleseniortylist: (state, action) => {
      state.ccitdelhicircularseniortylist = action.payload;
    },
    addccitdelhicircletandporder: (state, action) => {
      state.ccitdelhicirculartandporder = action.payload;
    },
    addccitdelhicircleotherdata: (state, action) => {
      state.ccitdelhicircularother = action.payload;
    },
  },
});

export const {
  addfolder,
  addcircularpdf,
  addimagedata,
  addaaykardata,
  addincometaxslabdata,
  addconstitutiondata,
  adddehicircledata,
  additefchqdata,
  adddepartmentaldata,
  addrecruitmentdata,
  addorganistaiondata,
  addcentralheadquartersdata,
  addseniortyfolder,
  addccitdelhicirclepromotionorder,
  addccitdelhicircleseniortylist,
  addccitdelhicircletandporder,
  addccitdelhicircleotherdata
} = pdfSlice.actions;

export default pdfSlice.reducer;
