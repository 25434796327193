export const data = [
  {
        id:1,
        name:"Chander Prakash Tokas",
        post:"President",
        mobile:"9910612752"
      },
      {
        id:2,
        name:"Rajesh Kumar",
        post:"General Secretary",
        mobile:"7678477079"
      }, {
        id:3,
        name:"Rahul Yadav",
        post:"Vice President",
        mobile:"8800992993"
      }, {
        id:4,
        name:"Rohtas Kumar Mann",
        post:"Vice President",
        mobile:"9013853073"
      }, {
        id:5,
        name:"Dharmendra Kumar Mishra",
        post:"Vice President",
        mobile:"8851899510"
      }, {
        id:6,
        name:"Bhupender Sharma",
        post:"President",
        mobile:"9013854574"
      }, {
        id:7,
        name:"Shikha Saini",
        post:"Assistant Secretary",
        mobile:"8860238500"
      },
      {
        id:8,
        name:"Dilip Kumar",
        post:"Assistant Secretary",
        mobile:"9279511840"
      },{
        id:9,
        name:"Amardeep",
        post:"Organising Secretary",
        mobile:"9802509888"
      },{
        id:10,
        name:"Vivek Sharma",
        post:"Organising Secretary",
        mobile:"8901020203"
      },{
        id:11,
        name:"Abhishek Singh",
        post:"Organising Secretary",
        mobile:"9717962761"
      },{
        id:12,
        name:"Ankaj Kumar",
        post:"Organising Secretary",
        mobile:"9570890987"
      },
      {
        id:13,
        name:"Dhanu Pratap Kushwaha",
        post:"Treasurer",
        mobile:"7763007362"
      },{
        id:14,
        name:"Pradeep Sahlot",
        post:"Assistant Treasurer",
        mobile:"9560481045"
      },{
        id:15,
        name:"Ajay Chhillar",
        post:"Executive Member",
        mobile:"9953056785"
      },{
        id:16,
        name:"Arun Tushir",
        post:"Executive Member",
        mobile:"7827678141"
      },{
        id:17,
        name:"Himanshu Saini",
        post:"Executive Member",
        mobile:"9810559810"
      },{
        id:18,
        name:"Shubham",
        post:"Executive Member",
        mobile:"9890394890"
      },{
        id:19,
        name:"Samridhi Chhabra",
        post:"Executive Member",
        mobile:"9873272171"
      },{
        id:20,
        name:"Ajay Kumar Dube",
        post:"Executive Member",
        mobile:"9415675383"
      },{
        id:21,
        name:"Sumit Sharma",
        post:"Executive Member",
        mobile:"9871200231"
      },{
        id:22,
        name:"Amit Kumar (B.No. 10)",
        post:"Executive Member",
        mobile:"8708591097"
      },{
        id:23,
        name:"Amit Kumar (B.No. 12)",
        post:"Executive Member",
        mobile:"9250000592"
      },{
        id:24,
        name:"Angad Yadav",
        post:"Executive Member",
        mobile:"8787257432"
      },{
        id:25,
        name:"Hardeep Singh",
        post:"Executive Member",
        mobile:"7027700014"
      },{
        id:26,
        name:"Avishek Bhowmick",
        post:"Executive Member",
        mobile:"9088385336"
      },{
        id:27,
        name:"Madan Lal Tetarwal",
        post:"Executive Member",
        mobile:"9829483078"
      },{
        id:28,
        name:"Arun Yadav",
        post:"Executive Member",
        mobile:"7597958850"
      },{
        id:29,
        name:"Ajeet Kumar Sinha",
        post:"Executive Member",
        mobile:"7531004662"
      },{
        id:30,
        name:"Himanshu Verma",
        post:"Executive Member",
        mobile:"0899392279"
      },{
        id:31,
        name:"Ankush Sharma",
        post:"Executive Member",
        mobile:"7206240470"
      },{
        id:32,
        name:"Omkar",
        post:"Executive Member",
        mobile:"9873373901"
      },{
        id:33,
        name:"Amit Kumar (B.No. 14)",
        post:"Executive Member",
        mobile:"6392386932"
      },{
        id:34,
        name:"Kalu Ram Meena",
        post:"Executive Member",
        mobile:"8003600610"
      }
];