import React from "react";

// import kushSharma from "../../images/manishinath-bhawan--300x225.jpg";
import "../style/homePage.css";
// import pageName from "../../images/pagename.jpg";
const HomePage = () => {
  return (
    <>
      <div
        className="outerGeneralProduct"
        style={{
          width: "60%",
          display: "flex",
          flexFlow: "wrap",
          height: "50rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#5e0202",
              fontVariant: "all-petite-caps",
              fontWeight: "bold",
              // marginBottom: "2rem",
              marginTop: "1.5rem",
              textShadow: "1px 1px 1px #000",
            }}
          >
            Welcome
          </h1>
          <h5
            style={{
              color: "#5e0202",
              fontFamily: "sans-serif",
              fontStyle: "normal",
              //
            }}
          >
            TO THE OFFICIAL WEBSITE OF
          </h5>

          <hr />
          <h4
            style={{
              fontFamily: "sans-serif",
              color: "#5e0202",
              fontWeight: "bold",
            }}
          >
            INCOME TAX EMPLOYEES FEDERATION
            <br />
          </h4>
          <h5
            style={{
              fontFamily: "sans-serif",
              color: "#5e0202",
              // fontWeight: "bold",
            }}
          >
            DELHI CIRCLE
          </h5>
          <p
            style={{
              textAlign: "justify",
              fontSize: "1.2rem",
              fontWeight: "500",
              padding: "2rem",
            }}
          >
            Income Tax Employees Federation is the monolithic organization of
            all non-gazetted employees in the Department in the Country which
            was established in the year 1953. The Federation is recognized by
            Govt of India. The present sanctioned strength of the Non Gazetted
            employees in the Department is 45000. All the Non Gazetted employees
            are primary members of the Organisation.
          </p>
        </div>
      </div>
    </>
  );
};

export default HomePage;

