import React from 'react'
import FlashNews from './FlashNews';
import SideNavbar from './SideNavbar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CCITTandPOrders = () => {


const [data, setData] = React.useState([])
const {
  pdf: { ccitdelhicirculartandporder },
} = useSelector((state) => state);

const navigate = useNavigate();
React.useEffect(() => {
  setData(ccitdelhicirculartandporder);
}, [ccitdelhicirculartandporder]);

const handleclick = (item) => {
  navigate("../iframe", { replace: true, state: { url: item.url } });
};
  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        margin: "auto",
      }}
    >
      <SideNavbar />

      <div
        style={{
          width: "60%",
          display: "flex",
          flexFlow: "column",
          overflowY: "scroll",
          height: "50rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#5e0202",
              fontVariant: "all-petite-caps",
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "1.5rem",
              textShadow: "1px 1px 1px #000",
            }}
          >
            T&P Orders
            <hr />
          </h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginInline: "20px",
              border: "0.5px solid rgba(46, 1, 1, 0.1)",
            }}
          >
            {data.map((item) => {
              return (
                <div
                  className="tabledata"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBlockEnd: "0.5px solid rgba(46, 1, 1, 0.1)",
                    cursor: "pointer",
                    padding: "10px",
                  }}
                >
                  <img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                    }
                    style={{
                      width: "20px",
                      margin: "0px 10px",
                    }}
                    alt="Logo"
                  />
                  <p
                    style={{
                      textDecoration: "none",
                      margin: "0px 10px",
                    }}
                    onClick={() => handleclick(item)}
                  >
                    {item.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <FlashNews />
    </div>
  );
}

export default CCITTandPOrders