import React from "react";
import SideNavbar from "./SideNavbar";
import FlashNews from "./FlashNews";
// import kushSharma from "../../images/manishinath-bhawan--300x225.jpg";
// import pageName from "../../images/pagename.jpg";

const About = () => {
  return (
    <>
      <div
        className="OuterMidcontant"
        style={{ width: "95%", display: "flex", margin: "auto" }}
      >
        <SideNavbar />
        <div
          className="outerGeneralProduct"
          style={{
            width: "60%",
            display: "flex",
            flexFlow: "column",
            overflowY: "scroll",
            height: "50rem",
          }}
        >
          {/* <div style={{ display: "flex", margin: "auto" }}>
          <img
            src={pageName}
            style={{ width: "2.5rem", height: "2.5rem" }}
            alt="qwerty"
          />
        </div> */}

          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#5e0202",
                fontVariant: "all-petite-caps",
                fontWeight: "bold",
                marginBottom: "2rem",
                marginTop: "1.5rem",
                textShadow: "1px 1px 1px #000",
              }}
            >
              About Us
              <hr style={{ width: "100%" }} />
            </h1>
            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",
                padding: "2rem",
              }}
            >
              Income Tax Employees Federation is the monolithic organization of
              all non-gazetted employees in the Department in the Country which
              was established in the year 1953. The Federation is recognized by
              Govt of India. The present sanctioned strength of the Non Gazetted
              employees in the Department is 45000. All the Non Gazetted
              employees are primary members of the Organisation. The Central
              Headquarters office is in its own building and named after one of
              the Founder leaders and a well known trade Union leader of yester
              years Com. Manishinath Gosh.
            </p>
          </div>
        </div>
        <FlashNews />
      </div>
    </>
  );
};

export default About;
