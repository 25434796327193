import React from "react";
import "../style/midcontent.css";

import FlashNews from "../pages/FlashNews";
import SideNavbar from "../pages/SideNavbar";
import HomePage from "../pages/HomePage";

const Midcontants = () => {
  return (
    <>
        <div
          className="OuterMidcontant"
          style={{
            width: "95%",
            display: "flex",
            margin: "auto",
          }}
        >
          {/* sideBar  start*/}
          <SideNavbar />
          {/* aside  end*/}

          {/* midcontant start*/}
          <HomePage />

          {/* midcontant end*/}
        <FlashNews />
        
        </div>
    </>
  );
};

export default Midcontants;
