import React from "react";
import Header from "./components/front/Header";
import Bannered from "./components/pages/Bannered";
import Footer from "./components/front/Footer"
const PageCommon = (props) => {
  return (
    <>
      <Header />
      <Bannered />

      {props.children}
      <Footer />
    
    </>
  );
};

export default PageCommon;
