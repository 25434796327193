import React, { useEffect } from "react";
import PageCommon from "./PageCommon";
import { Route, Routes } from "react-router-dom";
import About from "./components/pages/About";
import Midcontants from "./components/front/Midcontants";
import Home from "./components/front/Home";
import History from "./components/pages/History";
import Downloads from "./components/pages/Downloads";
import DelhiCircleLetter from "./components/pages/DelhiCircleLetter";
import ItefChqLetter from "./components/pages/ItefChqLetter";
import DepartmentaNews from "./components/pages/DepartmentaNews";
import RecruitmentRules from "./components/pages/RecruitmentRules";
import OrganistaionNews from "./components/pages/OrganistaionNews";
import CentralHeadquarters from "./components/pages/CentralHeadquarters";
import Aaykar from "./components/pages/Aaykar";
import Contact from "./components/pages/Contact";
import Incometaxslab from "./components/pages/Incometaxslab";
import { Iframe } from "./components/pages/Iframe";
import GalleryPage from "./components/pages/GalleryPage";
import Constitution from "./components/pages/Constitution";
import DownloadsSub from "./components/pages/component/DownloadsSub";

import { useDispatch } from "react-redux";
import { getAaykarData, getCCITDelhiCirculaPromotionOrderData, getCCITDelhiCircularOtherData, getCCITDelhiCircularSeniortyListData, getCCITDelhiCircularTandPOrderData, getCentralHeadquartersData, getConstitutionData, getDelhiCircleLetterData, getDepartmentalNewsData, getITEFCHQLetterData, getImageData, getIncomeTaxSlabData, getOrganistaionNewsData, getPdfCircular, getRecruitmentRulesData, getSeniortyfolderData, getfolderData } from "./redux/action";
import CcitDelhiCircle from "./components/pages/CcitDelhiCircle";
import CCITPromotionOrder from "./components/pages/CCITPromotionOrder";
import CCITTandPOrders from "./components/pages/CCITTandPOrders";
import CCITOthers from "./components/pages/CCITOthers";
import CCITSeniortyList from "./components/pages/CCITSeniortyList";
import SeniortySub from "./components/pages/component/SeniortySub";

const PageRoute = (props) => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPdfCircular());
    dispatch(getImageData());
    dispatch(getAaykarData());
    dispatch(getIncomeTaxSlabData());
    dispatch(getConstitutionData());
    dispatch(getDelhiCircleLetterData());
    dispatch(getITEFCHQLetterData());
    dispatch(getDepartmentalNewsData());
    dispatch(getRecruitmentRulesData());
    dispatch(getOrganistaionNewsData());
    dispatch(getCentralHeadquartersData());
    dispatch(getCCITDelhiCirculaPromotionOrderData())
    dispatch(getCCITDelhiCircularTandPOrderData())
    dispatch(getCCITDelhiCircularSeniortyListData())
    dispatch(getCCITDelhiCircularOtherData());
    dispatch(getfolderData())
    dispatch(getSeniortyfolderData())
  }, []);
  return (
    <>
      <PageCommon>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/history" element={<History />} />
          <Route path="/midcontant" element={<Midcontants />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/delhicircleletter" element={<DelhiCircleLetter />} />
          <Route path="/itefchqletter" element={<ItefChqLetter />} />
          <Route path="/departmentalnews" element={<DepartmentaNews />} />
          <Route path="/recruitmentrules" element={<RecruitmentRules />} />
          <Route path="/oraganizationnews" element={<OrganistaionNews />} />
          <Route path="/oraganizationnews" element={<CentralHeadquarters />} />
          <Route path="/ccitdelhicircle" element={<CcitDelhiCircle />} />
          <Route path="/ccitpromotionorders" element={<CCITPromotionOrder />} />
          <Route path="/ccitt&porders" element={<CCITTandPOrders />} />
          <Route path="/ccitseniortylist" element={<CCITSeniortyList />} />
          <Route path="/ccitothers" element={<CCITOthers />} />
          <Route path="/aaykar" element={<Aaykar />} />
          <Route path="/contactus" element={<Contact />} />
          <Route
            path="/centralheadquartyers"
            element={<CentralHeadquarters />}
          />
          <Route path="/incometaxslab" element={<Incometaxslab />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/constitution" element={<Constitution />} />
          <Route path="/Download/:folder" element={<DownloadsSub />} />
          <Route path="Pr.CCIT_Delhi_Circular/SeniortyListCCIT/:folder" element={<SeniortySub/> } />
        </Routes>
      </PageCommon>
    </>
  );
};


export default PageRoute;

