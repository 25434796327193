import React, { useEffect, useState } from "react";
import SideNavbar from "./SideNavbar";
import FlashNews from "./FlashNews";
import { useNavigate } from "react-router-dom";
import { AiFillFolder } from "react-icons/ai";
import "../style/hoverCommon.css";
import { useSelector } from "react-redux";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import { app } from "../firebase/firebase";
const folderdata = [
  { name: "/Download/APAR", id: 1 },
  { name: "/Download/Advance Loan Form", id: 2 },
  { name: "/Download/CGEGIS", id: 3 },
  { name: "/Download/CGHS Forms", id: 4 },
  { name: "/Download/Children Education Allowance claim", id: 5 },
  { name: "/Download/GPF", id: 6 },
  { name: "/Download/HBA", id: 7 },
  { name: "/Download/LTC", id: 8 },
  { name: "/Download/Leave Application", id: 9 },
  { name: "/Download/Permissions for purchase of movable property", id: 10 },
  // { name: "/Download/HBA Form", id: 11 },
];

const Downloads = () => {
  const navigate = useNavigate();
  const [Folder, setFolder] = useState([]);
  const [data, setData] = useState([]);

  const {
    pdf: { folder },
  } = useSelector((state) => state);

  const getdata = () => {
    Folder.map((item1) => {
      const storageref = ref(getStorage(app), item1);

      listAll(storageref).then((result) =>
        result.items.map((item) => {
          getDownloadURL(item).then((url) => {
            setData((prev) => [
              ...prev,
              { url: url, folder: item1, name: item.name },
            ]);
          });
        })
      );
    });
  };

  // console.log(data, "kkkkkkkkkk");

  const handleclick = (value) => {
    console.log(value)
    navigate(`../${value.name}`, { state: { folder: value } });
    // navigate("../about")
  };
  useEffect(() => {
    // getdata();
    setFolder(folder);
  }, []);

  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        margin: "auto",
      }}
    >
      <SideNavbar />

      <div
        style={{
          width: "60%",
          display: "flex",
          flexFlow: "column",
          overflow: "scroll",
          height: "50rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#5e0202",
              fontVariant: "all-petite-caps",
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "1.5rem",
              textShadow: "1px 1px 1px #000",
            }}
          >
            Downloads Forms
            <hr style={{ width: "100%" }} />
          </h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginInline: "20px",
              border: "0.5px solid rgba(46, 1, 1, 0.1)",
            }}
          >
            {folderdata &&
              folderdata.map((item) => {
                return (
                  <div
                    className="tabledata"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderBlockEnd: "0.5px solid rgba(46, 1, 1, 0.1)",
                      cursor: "pointer",
                      padding: "2px",
                    }}
                    onClick={() => handleclick(item)}
                  >
                    <AiFillFolder
                      size={35}
                      color="rgba(46, 1, 1, 0.5)"
                      style={{ margin: "0px 10px" }}
                    />
                    <p style={{ textAlign: "center", margin: "0px 5px" }}>
                      {item.name.split("/")[2]}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <FlashNews />
    </div>
  );
};

export default Downloads;
