import React from "react";
import SideNavbar from "./SideNavbar";
import FlashNews from "./FlashNews";
import { data } from "../contact";
import "../style/contact.css";

const Contact = () => {
  return (
    <>
      <div
        className="OuterMidcontant"
        style={{ width: "95%", display: "flex", margin: "auto" }}
      >
        <SideNavbar />
        <div
          className="outerGeneralProduct"
          style={{
            width: "60%",
            display: "flex",
            flexFlow: "column",
            flexDirection: "column",
            justifyContent: "center",
            // height:"50rem"
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#5e0202",
                fontVariant: "all-petite-caps",
                fontWeight: "bold",
                marginBottom: "2rem",
                marginTop: "1.5rem",
                textShadow: "1px 1px 1px #000",
              }}
            >
              Contact Us
              <hr />
            </h1>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="mainContact">
              <table className="table">
                <tr id="contacthead">
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Post</th>
                  <th>Contact Number</th>
                </tr>
                {data.map((item) => {
                  return (
                    <tr style={{ borderBottom: "0.5px solid #001" }}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.post}</td>
                      <td>{item.mobile}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>

          <div style={{}}>
            <div>
              <h2
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "2rem",
                  color: "#5e0202",
                  marginTop: "1rem",
                }}
              >
                Address
              </h2>
            </div>
            <div style={{}}>
              <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
                Room No.-160,&nbsp;Central Revenue Building,&nbsp;IP Estate, New
                Delhi-110002
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <p>
                📞 LandLine:- <span>+9187899277, </span> &nbsp;✉️ Email:-{" "}
                <span>contact@itefdelhi.org</span>
              </p>
            </div>
          </div>
        </div>
        <FlashNews />
      </div>
    </>
  );
};

export default Contact;
