import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../style/flashnews.css";
import { useSelector } from "react-redux";

const FlashNews = () => {
  const [flashpdf, setFlashPdf] = React.useState([]);

  const navigate = useNavigate();
  const {
    pdf: { circularpdf },
  } = useSelector((state) => state);

  React.useEffect(() => {
    setFlashPdf(circularpdf);
  }, [circularpdf]);

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: "2-digit",
      // minute: "2-digit",
      // second: "2-digit",
      // timeZoneName: "short",
    };
    const dateObject = new Date(dateTimeString);
    return dateObject.toLocaleDateString("en-US", options);
  };

  const handleclick = (item) => {
    navigate("../iframe", { replace: true, state: { url: item.url } });
  };
  return (
    <>
      <div
        // className="OuterSideNav"
        style={{ width: "20%", background: "#e9e8e8" }}
      >
        <p
          style={{
            backgroundColor: "rgba(46, 1, 1, 0.85)",
            color: "white",
            padding: "0.5rem 1.5rem",
            fontWeight: "500",
            fontSize: "1rem",
          }}
        >
          Latest Order / Circular
        </p>


        <marquee direction="up" height="700vh;" scrollamount="3" onmouseleave="start" onmouseover="stop" >

          {flashpdf &&
            flashpdf.map((item) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginBottom: "5px",
                  // backgroundColor: "#c9c5c5",
                  padding: "5px 10px",
                }}
                className="pdfdata1"
              >
                <div>
                  <img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                    }
                    style={{ width: "20px", marginRight: "5px" }}
                    alt="Logo"
                  />
                  <a
                    // href={item.url}
                    className="tag"
                    onClick={() => handleclick(item)}
                  >
                    {item.name}
                  </a>
                </div>
                <span
                  style={{ color: "rgba(46, 1, 1, 0.904)", fontSize: "10px" }}
                >
                  {formatDateTime(item.time)}
                </span>
              </div>
            ))}
        </marquee>

      </div>
    </>
  );
};

export default FlashNews;
