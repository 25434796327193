
// import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//   apiKey: "AIzaSyBYV3VadEU_9zTVcoNVCyIFLpUerTTMsO4",
//   authDomain: "itefdelhi-c8d43.firebaseapp.com",
//   projectId: "itefdelhi-c8d43",
//   storageBucket: "itefdelhi-c8d43.appspot.com",
//   messagingSenderId: "858757816858",
//   appId: "1:858757816858:web:319063abd94be9bf40a7a3",
// };

// export const app = initializeApp(firebaseConfig);




import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCddAR3G99vFutw2pUH5HlhBrmBgoLSbp0",
  authDomain: "itefdelhi-164c0.firebaseapp.com",
  projectId: "itefdelhi-164c0",
  storageBucket: "itefdelhi-164c0.appspot.com",
  messagingSenderId: "948309970904",
  appId: "1:948309970904:web:917d6ba1ec6d6939ae2cad",
  measurementId: "G-8J2KQ1L9KR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);