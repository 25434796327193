import React from "react";
import "../style/sideNavBar.css";
const SideNavbar = () => {
  return (
    <div className="outerSidebar">
      <div className="innerSidebar">
       
        <p>Utility Link Sites</p>
        <li>
          <a
            href="http://www.incometaxindia.gov.in/Pages/default.aspx"
            rel="noreferrer noopener"
            target="_blank"
          >
            CBDT, Government of India
          </a>
        </li>
        <li>
          <a
            href="http://incometaxindiaefiling.gov.in/"
            rel="noreferrer noopener"
            target="_blank"
          >
            e-Filing, Income Tax Department
          </a>
        </li>
        <li>
          <a
            href="http://finmin.nic.in/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Ministry of Finance
          </a>
        </li>
        <li>
          <a
            href="http://persmin.gov.in/DOPT.asp"
            rel="noreferrer noopener"
            target="_blank"
          >
            Dept of Personnel & Training
          </a>
        </li>
        <li>
          <a
            href="http://pgportal.gov.in/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Public Grievances
          </a>
        </li>
        <li>
          <a
            href="https://nsdl.co.in/"
            rel="noreferrer noopener"
            target="_blank"
          >
            NSDL
          </a>
        </li>
        <li>
          <a
            href="https://pfms.nic.in"
            rel="noreferrer noopener"
            target="_blank"
          >
            PFMS
          </a>
        </li>
      </div>

      <div className="innerSidebar">
        <p>ITEF Circles</p>
        <li>
          &nbsp;
          <a
            href="http://www.itefap.com/"
            rel="noreferrer noopener"
            target="_blank"
          >
            ITEF AP & Telengana Circle
          </a>
        </li>
        <li>
          &nbsp;
          <a
            href="http://www.itefbengalcircle.org"
            rel="noreferrer noopener"
            target="_blank"
          >
            IIEF Bengal Circle
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </li>
        <li>
          &nbsp;
          <a
            href="http://www.itefpatna.org"
            rel="noreferrer noopener"
            target="_blank"
          >
            ITEF Bihar & Jharkhand Circle
          </a>
        </li>
        <li>
          &nbsp;
          <a
            href="http://www.itefmpcg.org"
            rel="noreferrer noopener"
            target="_blank"
          >
            ITEF Bhopal Circle
          </a>
        </li>
        <li>
          &nbsp;
          <a
            href="http://www.itefkerala.com"
            rel="noreferrer noopener"
            target="_blank"
          >
            ITEF Kerala Circle
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </li>
        <li>
          &nbsp;
          <a
            href="http://www.itefitgoaodisha.com"
            rel="noreferrer noopener"
            target="_blank"
          >
            IIEF Odisha Circle
          </a>
        </li>
        <li>
          &nbsp;
          <a href="http://itgoa.org" rel="noreferrer noopener" target="_blank">
            ITGOA
          </a>
        </li>
      </div>
    </div>
  );
};

export default SideNavbar;
