import React from "react";
import "../style/footer.css";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaInstagram, FaFacebook, FaGooglePlus } from "react-icons/fa";
import footerlogo from "../../images/ITEFLOGO.jpeg";

import { Link, NavLink } from "react-router-dom";
import playStoreImage from "../../images/play1.png";
import appleIocImage from "../../images/ios.png";

const Footer = () => {
  return (
    <>
      <div className="Footer_main_container">
        <div className="Footer_first_container">
          <div className="Footer_second_container">
            <div className="footer-container1">
              <div className="footer-widget1">
                <div className="footer-logo">
                  <NavLink to="#">
                    <img
                      src={footerlogo}
                      alt="logo"
                      style={{
                        height: "3.5rem",
                        width: "3.5rem",
                        borderRadius: "100%",
                      }}
                    />
                  </NavLink>
                </div>

                <div className="footer-social-icon">
                  {/* <h4>Follow us</h4>
                  <div className="icon">
                    <Link className="icon1" to="/#">
                      <FaFacebook />
                    </Link>
                    <Link className="icon2" to="/#">
                      <AiFillTwitterCircle />
                    </Link>
                    <Link className="icon3" to="/#">
                      <FaInstagram />
                    </Link>
                    <Link className="icon4" to="/#">
                      <FaGooglePlus />
                    </Link>
                  </div> */}
                  <span>ITEF-DELHI</span>
                  <span>Circle</span>
                </div>
              </div>
            </div>

            <div className="footer-container2">
              <div className="footer-widget2">
                <div className="footer-widget2-heading">
                  <p>Useful Links</p>
                </div>
                <div className="link">
                  <div className="link2">
                    <ul>
                      <li>
                        <Link className="linkss" to="/">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link className="linkss" to="/about">
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link className="linkss" to="/history">
                          History
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="link2">
                    <ul>
                      <li>
                        <Link className="linkss" to="/constitution">
                          Constitution
                        </Link>
                      </li>
                      <li>
                        <Link className="linkss" to="/contactus">
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link className="linkss" to="/appointment">
                          Terms And Condition
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="link2">
                    <ul>
                      <li>
                        <Link className="linkss" to="/privacypolicy">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link className="linkss" to="/PageNotFound">
                          Latest News From ITEF
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-container3">
              <div className="footer-widget3">
                {/* <div className="footer-widget3-heading">
                  <p> App Links</p>
                </div> */}
                {/* <div className="appbtn">
                  <Link
                    to="http://play.google.com/store/apps/details?id=com.google.android.apps.maps"
                    className="download-blc-1"
                    target="blank"
                  >
                    <img
                      src={playStoreImage}
                      alt="..."
                      style={{ width: "2rem" }}
                    />{" "}
                  </Link>

                  <Link
                    to="/productNotFound"
                    className="download-blc-2"
                    target="blank"
                  >
                    <img
                      src={appleIocImage}
                      alt="..."
                      style={{ width: "2.5rem" }}
                    />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="copyrightContainer">
          <div className="copyrighText">
            <p>
              Copyright &copy; 2023
              <Link className="copyrighTextlink" to="/" target="blank">
                ITEF Delhi Circle.
              </Link>
              All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;


{/* <p>
  Site Developed and Maintained by :
  <a href="//api.whatsapp.com/send?phone=8789927877&text=WHATEVER_LINK_OR_TEXT_YOU_WANT_TO_SEND">
    {" "}
    Kush Kumar Sharma.
  </a>
</p>; */}