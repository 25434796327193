export const formatDateTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // timeZoneName: "short",
  };
  const dateObject = new Date(dateTimeString);
  return dateObject.toLocaleDateString("en-US", options);
};
