import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import FlashNews from './FlashNews';
import SideNavbar from './SideNavbar';
import { AiFillFolder } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import { app } from '../firebase/firebase';




// const folderdata = [
//   { name: "/SeniorList/Income Tax Inspector", id: 1 },
//   { name: "/SeniorList/OS", id: 2 },
//   { name: "/SeniorList/Senior TA", id: 3 },
//   { name: "/Download/Tax Assitant", id: 4 },
//   { name: "/SeniorList/Notice Server", id: 5 },
//   { name: "/SeniorList/MTS", id: 6 },
//   // { name: "/SeniorList/IncomeTaxInspector", id: 7 },
//   // { name: "/SeniorList/IncomeTaxInspector", id: 8 },
//   // { name: "/SeniorList/IncomeTaxInspector", id: 9 },
//   // { name: "/SeniorList/IncomeTaxInspector", id: 10 },
//   // { name: "/Download/HBA Form", id: 11 },
// ];

const CCITSeniortyList = () => {

const navigate = useNavigate();
  const [Folder, setFolder] = useState([]);
  const [data, setData] = useState([]);


  const {
    pdf: { seniortyfolder },
  } = useSelector((state) => state);



  const getdata = () => {
  
    Folder.map((item1) => {
      
      const storageref = ref(getStorage(app), item1);

      listAll(storageref).then((result) =>
        result.items.map((item) => {
          getDownloadURL(item).then((url) => {
         
            setData((prev) => [
              ...prev,
              { url: url, folder: item1, name: item.name },
            ]);
          });
        })
      );
    });
  };

 

  const handleclick = (value) => {
    console.log(value)
    navigate(`../${value.folder}`, { state: { folder: value } });
  };
  

  useEffect(() => {
    getdata();
    setFolder(seniortyfolder);
  
    
  }, [seniortyfolder,Folder]);
  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        margin: "auto",
      }}
    >
      <SideNavbar />

      <div
        style={{
          width: "60%",
          display: "flex",
          flexFlow: "column",
          overflowY: "scroll",
          height: "50rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#5e0202",
              fontVariant: "all-petite-caps",
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "1.5rem",
              textShadow: "1px 1px 1px #000",
            }}
          >
            Seniorty List
            <hr />
          </h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginInline: "20px",
              border: "0.5px solid rgba(46, 1, 1, 0.1)",
            }}
          >
            {data && data.map((item) => {
              return (
                <div
                  className="tabledata"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBlockEnd: "0.5px solid rgba(46, 1, 1, 0.1)",
                    cursor: "pointer",
                    padding: "10px",
                  }}
                >
                  {/* <img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                    }
                    style={{
                      width: "20px",
                      margin: "0px 10px",
                    }}
                    alt="Logo"
                  /> */}

                  <AiFillFolder
                    size={35}
                    color="rgba(46, 1, 1, 0.5)"
                    style={{ margin: "0px 10px" }}
                  />
                  <p
                    style={{
                      textDecoration: "none",
                      margin: "0px 10px",
                    }}
                    onClick={() => handleclick(item)}
                  >
                    {item.folder.split("/")[2]}
                    {/* {item.split("/")[2]} */}
                  </p>
                </div>
              );
            })}

          </div>
        </div>
      </div>
      <FlashNews />
    </div>
  );
}

export default CCITSeniortyList