import React from "react";
import Banner from "../../images/itefbanner.jpg";

const Bannered = () => {
  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5rem",
          marginBottom:"0.5rem"
        }}
      >
        {" "}
        <img
          src={Banner}
          alt=""
          style={{ width: "95%", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
        />
      </div>
    </>
  );
};

export default Bannered;
