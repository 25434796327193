import React from "react";
import GalleryView from "./GalleryView";
import { useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Spinner } from "react-bootstrap";

const GalleryPage = () => {
  const {
    pdf: { imagedata },
  } = useSelector((state) => state);
  const [image, setImage] = React.useState([]);

  const arrd = imagedata.map((item) => {
    var obj = {};
    obj["original"] = item.url;
    obj["thumbnail"] = item.url;
    return obj;
  });

  React.useEffect(() => {
    setImage(arrd);
    console.log("render");
  }, [imagedata]);

  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
        margin: "20px",
        // Height: "100vh",
        // backgroundColor: "red",
        minHeight: "100vh",
      }}
    >
      <div>
        <h1
          style={{
            color: "#5e0202",
            fontVariant: "all-petite-caps",
            fontWeight: "bold",
            marginBottom: "2rem",
            marginTop: "1.5rem",
            textShadow: "1px 1px 1px #000",
            textAlign: "center",
          }}
        >
          Photo Gallery
          <hr />
        </h1>
      </div>

      {/* <div className="gallery">
        {image.map((image, index) => (
          <GalleryView key={index} imageSrc={image.original} />
        ))} */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          Height: "100vh",
        }}
      >
        {image.length > 0 ? (
          <ImageGallery
            items={image}
            autoPlay={false}
            lazyLoad={true}
            showBullets
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </div>
  );
};

export default GalleryPage;
