import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useMatch,
} from "react-router-dom";
import PageRoute from "./PageRoute";
import { Iframe } from "./components/pages/Iframe";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";


const App = () => {
  useSelector(state => {
    console.log(state,"ppppp")
  })

  
  
  
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<PageRoute />}>
            <Route path="about" />
            <Route path="history" />
            <Route path="midcontant" />
            <Route path="downloads" />
            <Route path="delhicircleletter" />
            <Route path="itefchqletter" />
            <Route path="departmentalnews" />
            <Route path="recruitmentrules" />
            <Route path="oraganizationnews" />
            <Route path="aaykar" />
            <Route path="contactus" />
            <Route path="centralheadquartyers" />
            <Route path="ccitdelhicircle" />
            <Route path="/ccitpromotionorders" />
            <Route path="/ccitt&porders" />
            <Route path="/ccitseniortylist"  />
            <Route path="/ccitothers" />
            <Route path="incometaxslab" />
            <Route path="gallery" />
            <Route path="constitution" />
            <Route path="/Download/:folder" />
            <Route path="Pr.CCIT_Delhi_Circular/SeniortyListCCIT/:folder" />
          </Route>

          <Route path="iframe" element={<Iframe />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;


// import React from "react";
// import "./FlatList.css"; // Import CSS file for styling

// function App() {
//   // Sample array of items
//  const items = ["Item 1", "Item 2", "Item 3", "Item 4"];

//  return (
//    <div className="marquee-container">
//      <ul className="marquee-list">
//        {/* Use map function to render each item */}
//        {items.map((item, index) => (
//          <li key={index}>{item}</li>
//        ))}
//      </ul>
//    </div>
//  );
// }

// export default App;

