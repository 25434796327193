import React, { useEffect, useState } from "react";
import SideNavbar from "./SideNavbar";
import FlashNews from "./FlashNews";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../style/hoverCommon.css";
const Aaykar = () => {
  const [aykar, setAykar] = useState([]);
  const navigate = useNavigate();

  const handleclick = (item) => {
    navigate("/iframe", { state: { url: item.url } });
  };

  const {
    pdf: { aaykar },
  } = useSelector((state) => state);

  useEffect(() => {
    setAykar(aaykar);
  }, [aaykar]);

  return (
    <>
      <div
        className="OuterMidcontant"
        style={{ width: "95%", display: "flex", margin: "auto" }}
      >
        <SideNavbar />
        <div
          className="outerGeneralProduct"
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            // flexFlow: "wrap",
            overflowY: "scroll",
            height: "50rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#5e0202",
                fontVariant: "all-petite-caps",
                fontWeight: "bold",
                marginBottom: "2rem",
                marginTop: "1.5rem",
                textShadow: "1px 1px 1px #000",
              }}
            >
              Aaykaar
              <hr />
            </h1>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginInline: "20px",
              border: "0.5px solid rgba(46, 1, 1, 0.1)",
            }}
          >

             
            {aykar.map((item) => {
              return (
                <div
                  className="tabledata"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBlockEnd: "0.5px solid rgba(46, 1, 1, 0.1)",
                    cursor: "pointer",
                    padding: "10px",
                  }}
                  onClick={() => handleclick(item)}
                >
                  <img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                    }
                    style={{
                      width: "20px",
                      margin: "0px 10px",
                    }}
                    alt="Logo"
                  />

                  <p
                    style={{
                      textDecoration: "none",
                      margin: "0px 10px",
                    }}
                  >
                    {item.name}
                  </p>
                </div>
              );
            })}
            
          </div>
        </div>
      
         
             
          
         
                

              
              
              
             
              
        <FlashNews />
      </div>
    </>
  );
};

export default Aaykar;
