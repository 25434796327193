import React, { useState, useEffect } from "react";
import SideNavbar from "../SideNavbar";
import { Link, useNavigate } from "react-router-dom";
import FlashNews from "../FlashNews";
import { app } from "../../firebase/firebase";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { useLocation } from "react-router-dom";
import "../../style/hoverCommon.css";
import { useSelector } from "react-redux";

const DownloadsSub = () => {
  const location = useLocation();
  const [image, setImage] = useState([]);
  const [folder1, setFolder] = useState(null);
  const navigate = useNavigate();

  const {
    pdf: { folder },
  } = useSelector((state) => state);

  // console.log(folder, "kkdslflsdkfjbds")
  const storageref = ref(getStorage(app), `${location.state.folder.name}`);

  useEffect(() => {
    listAll(storageref).then((result) =>
      result.items.map((item) => {
        getDownloadURL(item).then((url) => {
          setImage((prev) => [...prev, { url: url, name: item.name }]);
        });
      })
    );
  }, []);

  const handleclick = (item) => {
    navigate("/iframe", { state: { url: item.url } });
  };

  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        margin: "auto",
      }}
    >
      <SideNavbar />

      <div
        style={{
          width: "60%",
          display: "flex",
          flexFlow: "column",
          overflowY: "scroll",
          height: "50rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#5e0202",
              fontVariant: "all-petite-caps",
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "1.5rem",
              textShadow: "1px 1px 1px #000",
            }}
          >
            Downloads Forms
            <hr style={{ width: "100%" }} />
            <h4>{location.state.folder.name.split("/")[2]}</h4>
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginInline: "20px",
            border: "0.5px solid rgba(46, 1, 1, 0.1)",
          }}
        >
          {image.map((item) => {
            return (
              <div
                className="tabledata"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBlockEnd: "0.5px solid rgba(46, 1, 1, 0.1)",
                  cursor: "pointer",
                  padding: "10px",
                }}
                onClick={() => handleclick(item)}
              >
                <img
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                  }
                  style={{ width: "20px", margin: "0px 10px" }}
                  alt="Logo"
                />
                <p
                  className="tablename"
                  style={{
                    textDecoration: "none",
                    margin: "0px 10px",
                  }}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <FlashNews />
    </div>
  );
};

export default DownloadsSub;
