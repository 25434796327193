import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import Spinner from "react-bootstrap/Spinner";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import FlashNews from "./FlashNews";
import SideNavbar from "./SideNavbar";
import { FaAngleRight, FaChevronLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const Constitution = () => {
  const {
    pdf: { constitution },
  } = useSelector((state) => state);

  console.log(constitution,"constitution called ")

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        margin: "auto",
        // overflowY: "scroll",
        // height: "50rem",
      }}
    >
      <SideNavbar />

      <div
        style={{
          width: "60%",
          display: "flex",
          flexFlow: "column",
          //   overflowY: "scroll",
          //   height: "38rem",
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#5e0202",
              fontVariant: "all-petite-caps",
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "1.5rem",
              textShadow: "1px 1px 1px #000",
            }}
          >
            Constitution
            <hr />
          </h1>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Document
            file={constitution[0]}
            options={options}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            }
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
          <div style={{ padding: "10px" }}>
            {" "}
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              style={{ border: " 0.5px solid grey", marginRight: "5px" }}
            >
              <FaChevronLeft />
            </button>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              style={{ border: " 0.5px solid grey" }}
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
      <FlashNews />
    </div>
  );
};

export default Constitution;
