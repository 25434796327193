import React from "react";
import "../style/header.css";
import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import itefHeaderlogo from "../../images/ITEFLOGO.jpeg";
import { AiFillCaretRight } from "react-icons/ai";

const Header = () => {
  
  return (
    <>
      <div className="OuterHeader">
        <div className="innerHeader">
          <div className="innerHeaderList">
            <Link className="links" to="/">
              <img
                style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                src={itefHeaderlogo}
                alt="Logo"
                title="Logo"
              />
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="/">
              Home
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="/about">
              About Us
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="/history ">
              History
            </Link>
          </div>

          <div className="innerHeaderList">
            <div className="dropdown">
              <div
                className="dropdown-toggle"
                // data-toggle="dropdown"
              >
                ITEF Corner
              </div>
              <div className="dropdown-menu">
                <div>
                  <Link className="links" to="delhicircleletter">
                    Delhi Circle Letter
                  </Link>
                </div>
                <div>
                  <Link className="links" to="itefchqletter">
                    ITEF CHQ Letter
                  </Link>
                </div>
                <div>
                  <Link className="links" to="departmentalnews">
                    Departmental News
                  </Link>
                </div>
                <div>
                  <Link className="links" to="recruitmentrules">
                    Recruitment Rules
                  </Link>
                </div>
                <div>
                  <Link className="links" to="oraganizationnews">
                    Organization News
                  </Link>
                </div>
                <div>
                  <Link className="links" to="centralheadquartyers">
                    Central Headquarters
                  </Link>
                </div>

                <div className="dropdown1">
                  <Link className="links " to="ccitdelhicircle">
                    Pr. CCIT Delhi
                  </Link>
                  <AiFillCaretRight size={12} />

                  <div className="dropdown-menu1">
                    <div>
                      <Link className="links abc" to="ccitpromotionorders">
                        Promotion Order
                      </Link>
                    </div>
                    <div>
                      <Link className="links abc" to="ccitseniortylist">
                        Seniorty List
                      </Link>
                    </div>
                    <div>
                      <Link className="links abc" to="ccitt&porders">
                        T&P Orders
                      </Link>
                    </div>
                    <div>
                      <Link className="links abc" to="ccitothers">
                        Others
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="dropdown2">
                  <Link className="links" to="centralheadquartyers">
                    CBDT Order
                  </Link>
                  <AiFillCaretRight size={12} />
                  <div className="dropdown-menu2">
                    <div>
                      <Link className="links abc" to="centralheadquartyers">
                        Promotion Order
                      </Link>
                    </div>
                    <div>
                      <Link className="links abc" to="centralheadquartyers">
                        T&P Orders
                      </Link>
                    </div>
                    <div>
                      <Link className="links abc" to="centralheadquartyers">
                        Others OM/Notification
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="gallery">
              Gallery
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="incometaxslab">
              Income Tax Slab
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="aaykar">
              Aaykaar
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="constitution">
              Constitution
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="/downloads">
              Downloads
            </Link>
          </div>

          <div className="innerHeaderList">
            <Link className="links" to="/contactus">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
