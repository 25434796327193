
import { app } from "./firebase";
import {
  getStorage,
  ref,
  getDownloadURL,
  listAll,
  getMetadata,
} from "firebase/storage";




export const listAllfolder = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app),"Download");
  listAll(storageref)
    .then((result) => {

console.log(result, "result ")
      const promises = result.prefixes.map((item) => {
        return item._location.path_;
      });
   resolve(promises)
    })
    .catch((error) => {
      reject(error);
    });
});


export const listAllCircular = new Promise((resolve, reject) => {
const storageref = ref(getStorage(app), "/LatestCircularOrder");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});



export const listAllImageData = new Promise((resolve, reject) => {

  const storageref = ref(getStorage(app), "Gallery");
  listAll(storageref).then((result) =>
  {
    
    const promise  = result.items.map((item) => {
     return Promise.all( [getDownloadURL(item)]).then(([url]) => {
       
       return {url}
      });
    })
  
    Promise.all(promise).then(data => {
   resolve(data)
    }).catch((error) => {
    reject(error)
  })
  
  }
  ).catch(error => {
    reject(error)
  })
})


export const listAllAaykar = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Aaykaar");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});

export const listAllIncomeTaxSlab = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Income_tax_slab");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});

export const listAllConstitution = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Constitution");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});


export const listAllDelhiCircleLetter = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Delhi_Circle_Letter");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});

export const listAllITEFCHQLetter = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/ITEF_CHQ_Letter");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});

export const listAllDepartmentalNews = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Departmental_News");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});

export const listAllRecruitmentRules = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Recruitment_Rule");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});



export const listAllOrganistaionNews = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Organization_News");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});



export const listAllCentralHeadquarters = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Central_headquarters");
  listAll(storageref)
    .then((result) => {
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});


export const listAllCCITDelhiCircularPromotionOrder = new Promise((resolve, reject) => {


  const storageref = ref(getStorage(app),"Pr.CCIT_Delhi_Circular/PromotioOrderCCIT");


  listAll(storageref)
    .then((result) => {

     
      const promises = result.items.map((item) => {
        
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {

            
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});

export const listAllCCITDelhiCircularSeniortyList = new Promise((resolve, reject) => {


  const storageref = ref(getStorage(app),"/Pr.CCIT_Delhi_Circular/SeniortyListCCIT");


  listAll(storageref)
    .then((result) => {


      
      
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
        
          ([url, metadata]) => {

            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});


export const listAllSeniortyListfolder = new Promise((resolve, reject) => {
  const storageref = ref(getStorage(app), "/Pr.CCIT_Delhi_Circular/SeniortyListCCIT");
  listAll(storageref)
    .then((result) => {

      console.log(result, "result seniorty list ")
      const promises = result.prefixes.map((item) => {
        return item._location.path_;
      });
      resolve(promises)
    })
    .catch((error) => {
      reject(error);
    });
});
export const listAllCCITDelhiCircularTandPOrder = new Promise((resolve, reject) => {


  const storageref = ref(getStorage(app),"/Pr.CCIT_Delhi_Circular/T&POrderCCIT");


  listAll(storageref)
    .then((result) => {
     
     
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});
export const listAllCCITDelhiCircularOthers = new Promise((resolve, reject) => {


  const storageref = ref(getStorage(app), "/Pr.CCIT_Delhi_Circular/OthersCCIT");


  listAll(storageref)
    .then((result) => {

      console.log(result, "lplplplplpl")
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});


export const listAllCBDTPromotionOrder= new Promise((resolve, reject) => {


  const storageref = ref(getStorage(app),);


  listAll(storageref)
    .then((result) => {

      // console.log(result.prefixes, "lplplplplpl")
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});

export const listAllCBDTTandPOrder = new Promise((resolve, reject) => {


  const storageref = ref(getStorage(app),);


  listAll(storageref)
    .then((result) => {

      // console.log(result.prefixes, "lplplplplpl")
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});


export const listAllCBDTOtherandNotification = new Promise((resolve, reject) => {


  const storageref = ref(getStorage(app),);


  listAll(storageref)
    .then((result) => {

      // console.log(result.prefixes, "lplplplplpl")
      const promises = result.items.map((item) => {
        return Promise.all([getDownloadURL(item), getMetadata(item)]).then(
          ([url, metadata]) => {
            return { url: url, name: item.name, time: metadata.timeCreated };
          }
        );
      });

      Promise.all(promises)
        .then((dataArray) => {
          resolve(dataArray);
        })
        .catch((error) => {
          reject(error);
        });
    })
    .catch((error) => {
      reject(error);
    });
});
