import {
  listAllfolder,
  listAllSeniortyListfolder,
  listAllAaykar,
  listAllCircular,
  listAllConstitution,
  listAllImageData,
  listAllIncomeTaxSlab,
  listAllDelhiCircleLetter,
  listAllITEFCHQLetter,
  listAllDepartmentalNews,
  listAllRecruitmentRules,
  listAllOrganistaionNews,
  listAllCentralHeadquarters,
  listAllCCITDelhiCircularPromotionOrder,
  listAllCCITDelhiCircularSeniortyList,
  listAllCCITDelhiCircularTandPOrder,
  listAllCCITDelhiCircularOthers,
} from "../components/firebase/firebaseApi";
import {
  addfolder,
  addseniortyfolder,
  addaaykardata,
  addcircularpdf,
  addconstitutiondata,
  addimagedata,
  addincometaxslabdata,
  adddehicircledata,
  additefchqdata,
  adddepartmentaldata,
  addrecruitmentdata,
  addorganistaiondata,
  addcentralheadquartersdata,
  addccitdelhicirclepromotionorder,
  addccitdelhicircleseniortylist,
  addccitdelhicircletandporder,
  addccitdelhicircleotherdata
} from "./PdfSlice";

export const getfolderData = () => (dispatch, getState) => {
  listAllfolder.then((data) => dispatch(addfolder(data)));
};
export const getSeniortyfolderData = () => (dispatch, getState) => {
  listAllSeniortyListfolder.then((data) => dispatch(addseniortyfolder(data)));
};

export const getPdfCircular = () => (dispatch, getState) => {
  listAllCircular.then((data) => dispatch(addcircularpdf(data)));
};

export const getImageData = () => (dispatch, getState) => {
  listAllImageData.then((data) => dispatch(addimagedata(data)));
};

export const getAaykarData = () => (dispatch, getState) => {
  listAllAaykar.then((data) => dispatch(addaaykardata(data)));
};

export const getIncomeTaxSlabData = () => (dispatch, getState) => {
  listAllIncomeTaxSlab.then((data) => dispatch(addincometaxslabdata(data)));
};

export const getConstitutionData = () => (dispatch, getState) => {
  listAllConstitution.then((data) => dispatch(addconstitutiondata(data)));
};

export const getDelhiCircleLetterData = () => (dispatch, getState) => {
  listAllDelhiCircleLetter.then((data) => dispatch(adddehicircledata(data)));
};

export const getITEFCHQLetterData = () => (dispatch, getState) => {
  listAllITEFCHQLetter.then((data) => dispatch(additefchqdata(data)));
};

export const getDepartmentalNewsData = () => (dispatch, getState) => {
  listAllDepartmentalNews.then((data) => dispatch(adddepartmentaldata(data)));
};

export const getRecruitmentRulesData = () => (dispatch, getState) => {
  listAllRecruitmentRules.then((data) => dispatch(addrecruitmentdata(data)));
};

export const getOrganistaionNewsData = () => (dispatch, getState) => {
  listAllOrganistaionNews.then((data) => dispatch(addorganistaiondata(data)));
};

export const getCentralHeadquartersData = () => (dispatch, getState) => {
  listAllCentralHeadquarters.then((data) =>
    dispatch(addcentralheadquartersdata(data))
  );
};



export const getCCITDelhiCirculaPromotionOrderData = () => (dispatch, getState) => {
  listAllCCITDelhiCircularPromotionOrder.then((data) =>
    dispatch(addccitdelhicirclepromotionorder(data))
  );
};


export const getCCITDelhiCircularSeniortyListData = () => (dispatch, getState) => {
  listAllCCITDelhiCircularSeniortyList.then((data) =>
    dispatch(addccitdelhicircleseniortylist(data))
  );
};

export const getCCITDelhiCircularTandPOrderData = () => (dispatch, getState) => {
  listAllCCITDelhiCircularTandPOrder.then((data) =>
    dispatch(addccitdelhicircletandporder(data))
  );
};

export const getCCITDelhiCircularOtherData = () => (dispatch, getState) => {
  listAllCCITDelhiCircularOthers.then((data) =>
    dispatch(addccitdelhicircleotherdata(data))
  );
};