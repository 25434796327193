import React from "react";
import { useLocation } from "react-router-dom";
import FlashNews from "./FlashNews";
import SideNavbar from "./SideNavbar";

export const Iframe = () => {
  const { state } = useLocation();

  return (
    <>
      <div
        className="outerGeneralProduct"
        style={{
          // width: "52%",
          display: "flex",
          flexFlow: "wrap",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <iframe
          width="100%"
          // src="https://www.africau.edu/images/default/sample.pdf"
          src={state.url}
          frameborder="1"
          allowFullScreen={true}
          // style={{ width: "-webkit-fill-available", flex: 0 }}
        ></iframe>
       

       
      </div>
    </>
  );
};
