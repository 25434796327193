import React, { useEffect, useState } from "react";

import { app } from "../firebase/firebase";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import SideNavbar from "./SideNavbar";
import FlashNews from "./FlashNews";
import { Link, useNavigate } from "react-router-dom";
import "../style/hoverCommon.css";
import { useSelector } from "react-redux";
// console.log(app)

const CcitDelhiCircle = () => {
  const [ccitdelhicircularnews, setCcitdelhicircularnews] = useState([]);

  const navigate = useNavigate();
  const handleclick = (item) => {
    // navigate("/iframe", { state: { url: item.url } });
    if (item == "Promotion Orders") 
    {
      navigate("/ccitpromotionorders");
    }
    if (item == "T&P Orders") {
       navigate("/ccitt&porders");
    }
    if (item == "Others") {
      navigate("/ccitothers");
    }
    if (item == "Seniorty List") {
      navigate("/ccitseniortylist");
    }
    ;
  };

  const {
    pdf: { ccitdelhicircular },
  } = useSelector((state) => state);

  useEffect(() => {
    setCcitdelhicircularnews(ccitdelhicircular);
  }, [ccitdelhicircular]);

  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        margin: "auto",
      }}
    >
      <SideNavbar />

      <div
        style={{
          width: "60%",
          display: "flex",
          flexFlow: "column",
          overflowY: "scroll",
          height: "50rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              color: "#5e0202",
              fontVariant: "all-petite-caps",
              fontWeight: "bold",
              marginBottom: "2rem",
              marginTop: "1.5rem",
              textShadow: "1px 1px 1px #000",
            }}
          >
            Pr. CCIT Delhi Circular
            <hr />
          </h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginInline: "20px",
              border: "0.5px solid rgba(46, 1, 1, 0.1)",
            }}
          >
            {/* {ccitdelhicircularnews.map((item) => {
              return (
                <div
                  className="tabledata"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBlockEnd: "0.5px solid rgba(46, 1, 1, 0.1)",
                    cursor: "pointer",
                    padding: "10px",
                  }}
                >
                  <img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                    }
                    style={{
                      width: "20px",
                      margin: "0px 10px",
                    }}
                    alt="Logo"
                  />
                  <p
                    style={{
                      textDecoration: "none",
                      margin: "0px 10px",
                    }}
                    onClick={() => handleclick(item)}
                  >
                    {item.name}
                  </p>
                </div>
              );
            })} */}

            {["Promotion Orders","T&P Orders","Seniorty List","Others"].map((item) => {
              return (
                <div
                  className="tabledata"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBlockEnd: "0.5px solid rgba(46, 1, 1, 0.1)",
                    cursor: "pointer",
                    padding: "10px",
                  }}
                >
                  {/* <img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                    }
                    style={{
                      width: "20px",
                      margin: "0px 10px",
                    }}
                    alt="Logo"
                  /> */}
                  <p
                    style={{
                      textDecoration: "none",
                      margin: "0px 10px",
                    }}
                    onClick={() => handleclick(item)}
                  >
                    {item}
                  </p>
                </div>
              );
            })}
            {/* <Link style={{ textDecoration: "none" }}>Promotion Order</Link>
            <Link style={{ textDecoration: "none" }}>T&P Orders</Link>
            <Link>Others</Link> */}
          </div>
        </div>
      </div>
      <FlashNews />
    </div>
  );
};

export default CcitDelhiCircle;
