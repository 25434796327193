import React from "react";
import Midcontants from "./Midcontants";

const Home = () => {
  return (
    <>
      <div>
        <Midcontants />
      </div>
    </>
  );
};

export default Home;
