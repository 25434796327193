import React from "react";
import FlashNews from "./FlashNews";
import SideNavbar from "./SideNavbar";

const History = () => {
  return (
    <>
      <div
        className="OuterMidcontant"
        style={{
          width: "95%",
          display: "flex",
          margin: "auto",
        }}
      >
        <SideNavbar />
        <div
          className="outerGeneralProduct"
          style={{
            width: "60%",
            display: "flex",
            flexFlow: "column",
            overflowY: "scroll",
            height: "50rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#5e0202",
                fontVariant: "all-petite-caps",
                fontWeight: "bold",
                marginBottom: "2rem",
                marginTop: "1.5rem",
                textShadow: "1px 1px 1px #000",
              }}
            >
              History
              <hr />
            </h1>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              Till 1949 the movement of Income Tax Employees was confined to
              some of the regions sans an allIndiabody. The initiation of
              forming an All India organization of the Income tax Employees was
              first mooted in 1949. Com. P.N.Das ofPatnaand Com. D.K. Khandekar
              of Mumbai toured all over the country and convened a meeting
              inDelhiin September, 1949 of various regional bodies. In that
              convention an apex body was formed. That convention gave a call
              for boycotting the Departmental Examination. Though the action was
              limited to the examinees, that was perhaps the first
              allIndiaagitation by the Income-tax Employees which elicited a
              massive response. Since the Association elected Shri Ashok Mehta,
              an eminent and illustrious person as its president, the Govt.
              refused to accord recognition under the specious plea that no
              outsider should be appointed or elected as Office Bearers, leading
              to the premature death of that body. Untiring efforts of Com. D.S.
              Rajaratnam ofNagpursucceeded and a convention was held atNagpuron
              10.02.1953. In that convention Com. S.K.Majumdar ofPatnaand Com.
              Rajarathnam were elected President and General Secretary
              respectively and continued in these posts till 1961. Though in May
              1954, the Federation was granted recognition, the office bearers
              of the Federation had to face innumerable vindictive and punitive
              actions from the Government for the militant stand taken by the
              Federation. Com. D.S. Rajarathnam was first transferred to a
              remote place in Madhya Pradesh, then placed under suspension and
              the then Central Board of Revenues proposed his compulsory
              retirement from service under the National Security Act.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              The Ist conference of the Federation was held at Kolkata in 1955.
              An agitation in the form of Demands Day on 30th March 1955 was
              organised which led to the resolution for the grant of Advance
              Increment for passing the Deptl. Examination, to treat Special pay
              as pay, increased the promotional quota and granted permission to
              publish the journal "Aayakar". In 1957, our Federation was one of
              the organizations that served the strike notice demanding 2nd pay
              commission. Tough the Govt. set up the 2nd CPC, its retrograde and
              disappointing recommendations lead to the five days glorious
              strike in 1960 in which our Federation actively participated. The
              services of many of our comrades who participated in the strike
              were terminated, good number of our leaders were suspended and
              many had to face other vindictive action unleashed by the Govt.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              In 1963 Com. G.S. Gnanam took over as General Secretary. The
              proposal of the Govt. to amend the provisions of Article 311 to
              deny constitutional protection to Civil servants was met with a
              massive protest by the CG Employees and ITEF took active part in
              that action programme. Under the leadership of Com. Gnanam, the
              ITEF could move into our own building purchased with the funds
              raised from the members. The building was appropriately named
              after its legendary President, Com. Maninishinath Ghosh. The
              building was renovated in the year 1998 into a multi storied
              structure by funds collected as donations from its members.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              At the 11th Conference held at Bombay in 1967, Com. Manishinath
              Gosh and Com. K.N.Nayagam were elected as President and General
              Secretary respectively. In 1969, Com. Pathik Banerji was elected
              as President of the Federation on the retirement of Com.
              Manishinath Ghosh. Com. K.N.Nayagam continued as General Secretary
              till 1979. ITEF was one of the founding organizations of the
              Confederation of Central Government employees. Com. G.S. Gnanm the
              then General Secretary of ITEF was elected as the Secretary
              General of Confederation. In the one day strike organized on 19th
              September, 1968, under the auspices of Confederation of Central
              Government employees and workers, demanding inter alia the need
              based minimum wage ITEF participated under the leadership of Com.
              Nayagam. The then Congress Government of Mrs. Indira Gandhi
              unleashed a reign of terror. Thousands of employees were
              terminated, leaders of the movements were jailed, and in the
              brutal police action a few comrades were shot dead. The
              recognition of the Federation and foreign service facility of the
              General Secretary were withdrawn. It was after protracted
              struggles the Govt. restored the recognition and vacated most of
              the victimizations.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              The ITEF and its units participated in the famous 1974 Strike
              along with the Railway Workers. Com. P.S. Rashtrapal, the then
              Joint Secretary of ITEF played a pivotal role in organizing the
              Railway workers inGujarat.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              In the 1979 conference held atKochi, Com. Nayagam was elected as
              President and Com. Pathik Banerji as General Secretary. In 1981,
              Com. Pathik Banerji took over again as President and Com. KKN
              Kutty was elected as General Secretary, who continued in that post
              till date. Com. Sanjiban Kumar Bhattacharya succeeded Com Pathik
              Banerji in 1996 and Com. Umesh Mehta became the President of ITEF
              in the 1998 to continue until his retirement in 2006.
              Com.V.S.R.Krishna the then General Secretary of ITEF,AP Circle
              took over as President at the Chennai Conference in 2006. In 2007
              December Com. KKN kutty superannuated from service but continued
              as secretary General till 2009 February. In the 27th All India
              Conference held at Mumbai Coms. K.P.Rajgopal and Ashok. B.Salunkhe
              took over as President and Secretary General respectively. In the
              28th Conference held at Mehsana(Gujarat) Coms.Ashok.B.Salunkhe and
              K.P.Rajgopal were elected as President and Secretary General
              respectively.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              The urge for a united movement with the promotee officers of the
              Income tax Department took organizational shape in 1987. The two
              Federations joined to create the Joint Council of action to spear
              head the strike action on 15th December, 1987. The joint movement
              of the two Federations, representing about 97% of the Tax
              personnel brought about career advancement of an unprecedented
              nature to all its members.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              The ITEF has faced insurmountable difficulties in 80s. During the
              struggles organised many of our comrades were victimized. The
              President of MP Circle, late Com. G.R.Kuril was dismissed from
              service under article 311(2)(b) of the Constitution without
              holding any inquiry. Late Com. Sharad Parab, the then General
              Secretary of ourBombay Circleand Com. Vinod Behari Srivastava, the
              General Secretary ofWestern UP Kanpur Circle, were also dismissed
              from service during the period. The seven-day hunger strikes by
              Com. PV Vargheese during the struggle of Rajasthan IT employees
              and the suspension of Com. Suresh Ajmera of Rajasthan have also
              taken place during the period. Our best efforts and struggles did
              not result in the reinstatement of Com. Sharad Parab and Com.
              Srivastava. After a prolonged struggle, the dismissal order of
              Com. G.R.Kuril was set-aside and suspension of Com. Suresh Ajmera
              was revoked.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              In 1993, the Government promulgated the new Recognition rules. To
              abide by the said rules, the ITEF which had a federal structure
              had to perforce become a unitary all India Association. Strenuous
              efforts were made to bring all category/cadre wise organizations
              as also the composite organization which was in existence in
              Tamilnadu and not affiliated to the ITEF to the fold of ITEF. With
              the co-operation of all concerned we succeeded in bringing about a
              monolithic organization of all non gazetted employees of the
              Income tax Department under the banner of ITEF. It had its
              salutary effect and impact on its future functioning evidenced
              from the fact that ITEF could organaise an indefinite strike on
              the call of the Confederation in 1996 against the United Front
              Govt.'s decision to discriminate the CGEs from the Raailway
              workers on the question of Bonus.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              The 5th CPC which submitted its report in 1997 inter-alia,
              recommended drastic downsizing of the Staff strength and abolition
              of about 3.5 lakhs posts. Upon the service of a strike notice by
              the organizations participating in the JCM, the Govt. was forced
              to set up a committee of Group of Ministers for serious
              negotiations. The discussions which prolonged continuously for 36
              hours with the Group of Ministers resulted in the conceding of 40%
              rise in the pay. In this negotiation the ITEF Secretary General
              Com. K.K.N.Kutty, played a pivotal role.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              The Cadre Restructuring of the Income tax Department, with the
              joint effort of all the three All India Federations viz. ITEF,
              ITGSF and the IRS Association initiated in the year 1998 got the
              seal of approval from the Government in 2000. The proposal was in
              Toto approved by the Govt. which has no precedence in the recorded
              history of Governmental functioning. The Cadre restructuring
              brought promotion to almost all employees and officers within the
              span of three years, 60% of them getting the benefit in the year
              2001 itself. After the V-CPC report was submitted the ITEF took
              initiative to coordinate all the Federations in the Department of
              Revenue and the COC of Federations in the Department of Revenue
              came into existence and forged a united movement which resulted in
              the upward revision of the pay scales of Inspectors and Group B
              Officers.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",

                padding: "0rem 2rem",
              }}
            >
              To mark the 50th year of our struggle and advancement, ITEF
              organized special functions at all State Capitals beginning from a
              Special Conference convened at Mumbai and concluding with the
              Silver Jubilee Conference at Kolkata in February, 2003. The
              Federation Headquarters throughout the period has strived to
              maintain contact with its grass root level members through its
              monthly journal Aayakar. Since 1981 it has not missed to be
              published anytime till date. The journal remains a powerful link
              between the members and the CHQ.
            </p>

            <p
              style={{
                textAlign: "justify",
                fontSize: "1.2rem",
                fontWeight: "500",
                padding: "0rem 2rem",
              }}
            >
              ITEF is no doubt today a militant organization, capable of
              organizing its members for industrial action. In the last one day
              strike on 20th August, 2008 organized under the auspices of the
              Sponsoring Committee of Central Trade Unions and Federations
              against the continuing neo liberal economic, ITEF could register
              cent per cent participation of its members. The Offices of the
              Income tax Department throughout the country remained closed on
              that day due to the impact of the strike. The growing influence of
              ITEF in the Trade Unions in the country, especially among the
              Central Govt. employees Federations could be gauged from the fact
              that its Secretary General , Com. K.K.N.Kutty was elected as the
              Secretary General of the Confederation of Central Govt. Employees
              and Workers in the national convention in 2006 and as a Directive
              Committee Member of the Trade Union International- public and
              allied services under the World Federation of Trade Unions
            </p>
          </div>
        </div>
        <FlashNews />
      </div>
    </>
  );
};

export default History;
